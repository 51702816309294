import {Carousel,Container,Row,Col} from 'react-bootstrap';
import catalogos from '../assets/img/catalogos.svg'
import carritocompra from '../assets/img/carritocompra.svg'
import mediodepago from '../assets/img/mediodepago.svg'
import pasareladepago from '../assets/img/pasareladepago.svg'
import resultadopagoenlinea from '../assets/img/resultadopagoenlinea.svg'



function FiveSectionComponent() {
  return (
    <Container fluid className="mt-5 mb-20  bg-section2  ">
    <Row className="mb-md-1">
        <Col md={12}>
            <h2 className="text-center title-third-section">Experiencia de compra 🛒</h2>
        </Col>
        <Col md={12}>
            <p className="text-center subtitle-third-section">Catálogos, Carrito de compra, Pagos en <strong>automático</strong></p>
        </Col>
    </Row>
    <Row >
    <div className="col-12">
        <Carousel fade>
        <Carousel.Item interval={900} >
        <h1 className="text-center "> <span className="green-text"><strong>Así lucen tús Catálogos</strong></span></h1>
        <img src={catalogos} alt={"Catálogos"}  className="d-block mx-auto" />
        <Carousel.Caption>
          <h3> >>>>> </h3>
        </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={900}>
        <h1 className="text-center "> <span className="green-text"><strong>Carrito de Compra</strong></span></h1>
        <img src={carritocompra} alt={"carritocompra"} className="d-block mx-auto" />
        <Carousel.Caption>
          <h3> >>>>> </h3>
        </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={900}>
        <h1 className="text-center "> <span className="green-text"><strong>Medios de Pagos</strong></span></h1>
        <img src={mediodepago} alt={"mediodepago"} className="d-block mx-auto" />
        <Carousel.Caption>
          <h3> >>>>> </h3>
        </Carousel.Caption>
        </Carousel.Item >
        <Carousel.Item interval={900} >
        <h1 className="text-center "> <span className="green-text"><strong>Pagos en línea</strong></span></h1>
        <img src={pasareladepago} alt={"resultadopagoenlinea"} className="d-block mx-auto" />
        <Carousel.Caption>
          <h3> >>>>> </h3>
        </Carousel.Caption>
        </Carousel.Item>  
        <Carousel.Item interval={900}>
        <h1 className="text-center "> <span className="green-text"><strong>Resultados de Pagos</strong></span></h1>
        <img src={resultadopagoenlinea} alt={"resultadopagoenlinea"} className="d-block mx-auto" />
        </Carousel.Item>  

        </Carousel>
    </div>
    </Row>
    </Container>
    
  );
}

export default FiveSectionComponent;