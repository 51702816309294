import { Card, Col, Container, Row } from "react-bootstrap"
import image1 from '../assets/img/1.png'
import image2 from '../assets/img/2.png'
import image3 from '../assets/img/4.png'
import image4 from '../assets/img/3.png'
import image5 from '../assets/img/5.png'

function ThirdSectionComponent() {
    return (
        <Container className="mt-5 mb-21">
            <Row className="mb-md-3">
                <Col md={12}>
                    <h2 className="text-center title-third-section">Mejora la experiencia de tus clientes con nuestro servicio</h2>
                </Col>
                <Col md={12}>
                    <p className="text-center subtitle-third-section">Wappito es la mejor opción para vender a tus clientes.</p>
                </Col>
            </Row>
            <Row>

                <Col xs={12} md={3}>
                    <Card className="pt-5 pb-3" border="light">
                        <Card.Img variant="top" src={image3} className="imgage-icon-third-section d-block mx-auto" />
                        <Card.Body>
                            <Card.Title className="text-center title-card-third-section">Con Wappito te multiplicás por 60</Card.Title>
                            <Card.Text className="text-center description-card-third-section">Crece sin estrés, Wappito equivale como si tuvieras 60 asesores trabajando por ti 24/7</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} md={3}>
                    <Card className="pt-5 pb-3" border="light">
                        <Card.Img variant="top" src={image1} className="imgage-icon-third-section d-block mx-auto" />
                        <Card.Body>
                            <Card.Title className="text-center title-card-third-section">Ventas en tiempo record</Card.Title>
                            <Card.Text className="text-center description-card-third-section">Reduce tiempos de atención a 0 min de espera, y a 2 min una venta exitosa</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3}>
                    <Card className="pt-5 pb-3" border="light">
                        <Card.Img variant="top" src={image4} className="imgage-icon-third-section d-block mx-auto" />
                        <Card.Body>
                            <Card.Title className="text-center title-card-third-section">Tus clientes informados en tiempo real</Card.Title>
                            <Card.Text className="text-center description-card-third-section">Notifica estado de pedidos en tiempo real</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} md={3}>
                    <Card className="pt-5 pb-3" border="light">
                        <Card.Img variant="top" src={image5} className="imgage-icon-third-section d-block mx-auto" />
                        <Card.Body>
                            <Card.Title className="text-center title-card-third-section">Control total de Chats y Pedidos</Card.Title>
                            <Card.Text className="text-center description-card-third-section">Alertas en tú WhatsApp personal de tiempo altos de respuesta, Reportes de chats y pedidos</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>
        </Container>
    )
}

export default ThirdSectionComponent