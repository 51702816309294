import { Button, Col, Row, Stack } from "react-bootstrap"

function FourSectionComponent() {
    return (
        <Row className="d-none d-md-block container-green-footer">
            <Col md={10} className="d-block mx-auto container-section4">
                <h1 className="text-center">Llega a multitud de clientes potenciando tus ventas con nuestro asesor virtual</h1> <br />
                <Stack direction="horizontal" gap={2} className="d-flex justify-content-center">
                    {/* <Button className="btn-lg btn-dark">Conoce más</Button> */}
                    <a href={"https://wa.me/573243984410?text=Hablame+de+Wappito"} target="_blank" rel="noopener noreferrer">
                        <Button className="btn-lg btn-dark">Reserva tu demo ahora</Button>
                    </a>
                </Stack>
            </Col>
        </Row>   
    )
}

export default FourSectionComponent