import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/GeneralStyles.css'
import FirstSectionComponent from "./components/FirstSectionComponent";
import HeaderComponent from "./components/HeaderComponent";
import SecondSectionComponent from "./components/SecondSectionComponent";
import ThirdSectionComponent from './components/ThirdSectionComponent';
import FooterComponent from './components/FooterComponent';
import FiveSectionComponent from './components/FiveSectionComponent';


function App() {
  return (
   <div id='root'>
      <HeaderComponent />
      <FirstSectionComponent />
      <SecondSectionComponent />
      <ThirdSectionComponent />
      <FiveSectionComponent />
      <FooterComponent />
      
   </div>
  );
}

export default App;
