import { Col, Container, Navbar, Row, Nav, Stack } from "react-bootstrap";
import icon_twitter_footer from "../assets/img/twitter-footer-icon.svg";
import icon_linkedin_footer from "../assets/img/linkedin-footer-icon.svg";
import icon_facebook_footer from "../assets/img/facebook-footer-icon.svg";
import icon_social_media from "../assets/img/social-footer-icon.svg";
import FourSectionComponent from "../components/FourSectionComponent";
import Image from "react-bootstrap/Image";

function FooterComponent() {
    return (
        <div className="w-100 container-footer d-none d-md-block">
            <Container>

                <FourSectionComponent />

                <Row className="mt-5">
                    <Col md={12}>
                        <h3 className="title-footer">wappito</h3>
                    </Col>
                    <Col md={4} className="mt-2">
                        <p className="text-white">El asesor virtual que te ayudará a potenciar tus ventas.</p>
                    </Col>
                </Row>
                <Row>
                   <Navbar expand="lg" className="main-menu-footer">
                        <Nav className="me-auto">
                            <Nav.Link href="#home">Inicio</Nav.Link>
                            <Nav.Link href="#home">Beneficios</Nav.Link>
                            <Nav.Link href="#home">Acerca de Wappito</Nav.Link>
                            <Nav.Link href="#home">Términos y condiciones</Nav.Link>
                            <Nav.Link href="#home">Ayuda</Nav.Link>
                        </Nav>
                    </Navbar> 
                </Row>
                <hr className="line-divider" />
                <Row className="mt-4 d-flex justify-content-between">
                    <Col md={4}>
                        <p className="text-copyright">© 2023 Wappito. All rights reserved.</p>
                    </Col>
                    <Col md={8}>
                        <Stack direction="horizontal" gap={4} className="justify-content-end">
                            <a href="#home"><Image src={icon_twitter_footer}/></a>
                            <a href="#home"><Image src={icon_linkedin_footer}/></a>
                            <a href="#home"><Image src={icon_facebook_footer}/></a>
                            <a href="#home"><Image src={icon_social_media} /></a>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FooterComponent