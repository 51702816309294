import { Button, Container, Row } from "react-bootstrap"

function FirstSectionComponent() {
    return (
       <Container className="my-5">
        <Row>
            <div className="col-12 col-md-9 col-lg-9">
                <h1 id="main-text-first-section">
                Procesa hasta <span className="green-text">60</span> pedidos por minutos en <span className="green-text">WhatsApp</span>, sin mover un dedo en tú <span className="green-text">Restaurante</span>
                </h1>
            </div>
        </Row>
        <Row>
            <div className="col-12 col-md-7 col-lg-7 mt-3">
            <p className="second-paragraph-first-section">Catálogos, Pagos, Reservas. <br />en <span className="green-text"><strong>WhatsApp</strong></span> <strong> 💅¡sin mover un dedo! 😌</strong></p>
            <p className="second-paragraph-first-section">Clientes enamorados &#128525;<br /> </p>
            {/* <p className="second-paragraph-first-section">Todo para vender a multitudes, a precio de un café diario<br /> </p> */}

            </div>
        </Row>
        {/* <Row className="mt-3">
        <div className="col-12 col-md-6">
         
          <a href={"https://wa.me/573243984410?text=Hablame+de+Wappito"} target="_blank" rel="noopener noreferrer">
            <Button className="btn-lg btn-green">Reserva tu demo ahora</Button>
          </a>
        </div>
      </Row> */}
       </Container>
    )
}

export default FirstSectionComponent