import React from "react";
import { Container, Row, Carousel } from "react-bootstrap";
import image1 from '../assets/img/wok.png';
import image2 from '../assets/img/linkmys.png';
import image3 from '../assets/img/surtikia.png';

function SecondSectionComponent() {
//   const customButtonStyle = {
//     backgroundColor: "#1A1E2C", // Color de fondo personalizado
//     border: "#1A1E2C", // Quita el borde
//   };

  return (
    <Container fluid className="bg-section2 py-5">
      <Row>
        <div className="col-12">
          <p className="text-center description-section2">Hemos trabajado con estos <span className="green-text">increíbles comercios</span></p>
        </div>
        <div className="col-12">
          <Carousel>
            <Carousel.Item interval={900}>
              <img
                className="d-block mx-auto"
                src={image1}
                alt="Imagen 1"
              />
            </Carousel.Item>
            <Carousel.Item interval={900}>
              <img
                className="d-block mx-auto"
                src={image2}
                alt="Imagen 2"
              />
            </Carousel.Item>
            <Carousel.Item interval={900}>
              <img
                className="d-block mx-auto"
                src={image3}
                alt="Imagen 3"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </Row>
    </Container>
  );
}

export default SecondSectionComponent;
