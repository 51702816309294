import { Container , Image} from "react-bootstrap"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Button } from 'react-bootstrap';
import logo from '../assets/img/LogoWappito.svg';


function HeaderComponent() {
    return (
        <Navbar expand="lg" bg="light" className='bg-white'>
            <Container>
                <Image src={logo} className="d-block mx-auto"/>
                {/* <Navbar.Brand href="#home" className='mulish-font-navbar'>wappito</Navbar.Brand> */}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className='mx-auto'>
                        <Nav.Link href="#home" className='Space_Grotesk-light menu-item'>inicio</Nav.Link>
                        <Nav.Link href="#link" className='Space_Grotesk-light menu-item'>sobre nosotros</Nav.Link>
                        <Nav.Link href="#link" className='Space_Grotesk-light menu-item'>beneficios</Nav.Link>
                       
                    </Nav>
                    {/* <Nav className=''>
                        <Nav.Link href="#home" className='poppi-light menu-item'><Button className="btn-pink">Reserva una demo</Button></Nav.Link>
                       
                    </Nav> */}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default HeaderComponent